
<template>
  <!-- 富文本 -->
  <div style="width: 100%;">
    <editor v-model="content" :init="init" :disabled="disabled"></editor>
    
    
      <van-dialog v-model="show" title="选择图片" show-cancel-button @confirm="setContent" @cancel="resetUpload">
          <div class="uploadImg">
              <van-uploader
                  v-model="fileList"
                  multiple
                  :max-count="4"
                  :after-read="afterRead"
                  :before-read="beforeRead"
                  upload-icon="plus"
              />
          </div>
      </van-dialog>
  </div>
</template>


<script>
// import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/icons/default/icons";
// import "tinymce/plugins/image";

export default {
 name: 'CustomTinymce',
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 250
    }
  },
  data() {
     let self = this;
    return {
        fileList: [],
        show: false,
      //初始化配置
      init: {
        language:'zh-Hans',
        skin_url: "/tinymce/skins/ui/oxide",

        height: this.height,
        mobile: {
            menubar: false
        },
        plugins: [
            // 'image'
        ],
        toolbar: 'clrUpload|forecolor|fontsize|undo redo',
        
        setup(context) {
            context.ui.registry.addButton('clrUpload',{
                icon:'image',
                onAction:function(){
                    console.log(context)
                    self.show = true
                }
            });
        }
      },
      content: this.value
    }
  },
    
    destroyed() {
    },
    mounted() {
    window.tinymce.init({});
  },
  methods: {
      resetUpload() {
          this.fileList = []
      },
      setContent() {
          this.fileList.forEach((e) => {
              if (e.status === 'success') {
                  this.content += `<img src="${e.content}" />`
              }
          })
          this.fileList = []
      },
      beforeRead(file) {
          if (file.type !== 'image/jpeg') {
              this.$toast('请上传 jpg 格式图片');
              return false;
          }
          return true;
      },
      // 组件上传
      afterRead(file) {
          file.status = 'uploading';
          file.message = '上传中...';
          console.log(file.content); // 给到的是一个base64字符串
          
          //上传请求
          setTimeout(() => {
              // file.status = 'failed';
              // file.message = '上传失败';
              file.status = 'success';
              file.message = '上传成功';
              // 上传完了之后可以把后台返回的链接给到content
              // file.content = res.data.url
              // this.fileList.push(file);
          }, 1000);
      },
    
  },
  watch: {
    value(newValue) {
      this.content = newValue;
    },
    content(newValue) {
      this.$emit("input", newValue);
    }
  }
};
</script>
<style lang="less" scoped>

::v-deep .tox .tox-edit-area__iframe {
    background-color: transparent;
}
::v-deep .tox-tinymce {
    border-radius: 0;
    border: none;
}
.uploadImg {
    padding: 10px 20px 20px 20px;
}
::v-deep .van-uploader__preview, ::v-deep .van-uploader__upload {
    height: 64px;
    width: 77px;
    margin-bottom: 0;
    margin-top: 10px;
    margin-right: 0;
    margin-left: 10px;
    border-radius: 5px;
}

::v-deep .van-uploader__upload {
    border: 1px dashed #DDDDDD;
    background-color: #fff;
    
}
::v-deep .van-image {
    height: 64px;
    width: 77px;
    border-radius: 5px;
}
</style>
